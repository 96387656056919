import req from "../methods/req"
import method from '../methods/method'

// 订单列表
export const preExportList = function ({
	_this,
	data = {}
}){
    return method.get_list({
		_this,
        url:'/manage/crm.export/preExportList',
        data
    })
}

// 获取当前正在导出的报表进度及该报表相关信息
export const getExportProgress = function (data) {
	return req({
		url: '/manage/crm.export/getExportProgress',
		...data
	})
}

// 获取正在等待导出完成的报表进度及该报表相关信息
export const getExport = function (data) {
	return req({
		url: '/manage/crm.export/getExport',
		...data
	})
}

// 任务同步列表
export const getSystemTaskList = function (data) {
	return method.get_list({
		url: '/manage/crm.system/getSystemTaskList',
		...data
	})
}
